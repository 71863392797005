.AboutUsTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: rgb(192, 232, 234);
  margin-top: 5vh;
}

.aboutUsName{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 3vmin);
  color: rgb(192, 232, 234);
  margin-top: 1wh;
  /* font-style: italic; */
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.aboutUsText{
    font-size: calc(10px + 1vmin);
    color: rgb(192, 232, 234);
    margin-top: 1wh;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1vw;
    padding-right: 2vw;
    /* max-width: 100px ; */
    height: max-content ;
    width: 100vw;
    max-width: 700px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
    text-wrap: wrap;
    word-wrap: break-word ;
    white-space: nowrap ;
    /* display: flex ;
    flex-direction: column;
    flex-wrap: wrap; */
    /* justify-content: center; */
    white-space: pre-line;
    justify-content:center;
    /* overflow: visible; */
    /* overflow: auto; */
}

.imagesItem{
    padding: inherit;
    margin-left: 2vh;
    margin-right: 2vh;
    width: max-content;
    max-width: 700px;
    margin-top: 1vh;
    height: max-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-wrap: wrap;
    word-wrap: break-word ;
    white-space: nowrap ;
}

img {
    padding: inherit;
   margin-left: auto;
   margin-right: auto;
   margin-top: 2vh;
    width: 100vw;
    max-width:700px;
    max-height: 700px; 
    height: max-content;
    object-fit: cover;
    justify-content: center;
    align-self: center;
    display: block;
}

.imagesContainer {
    flex-direction: row;
    display : flex;
    padding: inherit;
    margin-left:auto;
    margin-right:auto;
    margin-top: 1vh;
    margin-bottom: 2vh;
    overflow: auto;
    white-space: nowrap;
    width: 100vw;
    max-width: 700px;
    height: max-content;
    /* justify-content:center; */
}


