.ContactUsTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: rgb(192, 232, 234);
  margin-top: 5vh;
}

.connectWithUs{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: rgb(192, 232, 234);
  margin-top: 1vw;
}

.iconsMargins{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: rgb(192, 232, 234);
  margin-top: 10px;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-bottom: 2vh;
}

.queryTitle{
    font-size: calc(10px + 1vmin);
    color: rgb(192, 232, 234);
    margin-top: 2vw;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1vw;
    padding-right: 2vw;
    /* max-width: 100px ; */
    height: max-content ;
    width: 100vw;
    max-width: 700px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
    text-wrap: wrap;
    word-wrap: break-word ;
    white-space: nowrap ;
    /* display: flex ;
    flex-direction: column;
    flex-wrap: wrap; */
    /* justify-content: center; */
    white-space: pre-line;
    justify-content:center;
    /* overflow: visible; */
    /* overflow: auto; */
}

.submitButton{
    background-color: #27333f;
    font-size: calc(10px + 1vmin);
    color: rgb(192, 232, 234);
    margin-top: 2vw;
    /* margin-left: auto;
    margin-right: auto; */
    margin:auto;
    padding-left: 1vw;
    padding-right: 1vw;
    /* max-width: 100px ; */
    height: 7vh ;
    width: 10vw;
    max-width: 700px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
    display:block;
    text-align: center;
    /* text-wrap: wrap;
    word-wrap: break-word ;
    white-space: nowrap ; */
    /* display: flex ;
    flex-direction: column;
    flex-wrap: wrap; */
    /* justify-content: center; */
    white-space: pre-line;
    /* justify-content:center; */
    /* overflow: visible; */
    /* overflow: auto; */
}

.contactUs{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 3vmin);
  color: rgb(192, 232, 234);
  margin-top: 1wh;
  /* font-style: italic; */
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.queryTextbox{
     background-color: #27333a;
     rows: 2;
    font-size: calc(10px + 1vmin);
    color: rgb(192, 232, 234);
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top:1vw;
    padding-bottom:1vw;
    /* max-width: 100px ; */
    /* height: max-content ; */
    width: 100vw;
    max-width: 700px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
    text-wrap: wrap;
    word-wrap: break-word ;
    white-space: nowrap ;
    /* display: flex ;
    flex-direction: column;
    flex-wrap: wrap; */
    /* justify-content: center; */
    white-space: pre-line;
    justify-content:center;
    display: block;
    /* overflow: visible; */
    /* overflow: auto; */
}

